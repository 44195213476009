import {
  Autocomplete,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import JobResumeTab from "../components/JobResumeTab";
import {
  StyledButton,
  StyledFlexRowView,
} from "../../CandidateHub/Components/StyledComponents";
import FormPrePop from "../../PostJob/Components/FormPrePop";
import HardSoftSkill from "../../PostJob/Components/HardSoftSkill";
import Education from "../../PostJob/Components/Education";
import OverallChart from "../../PostJob/Components/OverallChart";
import Profile from "../../InteractionHub/Components/Profile";
import InteractionHistory from "../../InteractionHub/Components/InteractionHistory";
import axios from "axios";
import {
  getJobData,
  getMatchedJdResumeData,
  JdAddNotes,
  JdGetNotes,
  uploadJdResume,
} from "../../../api/api";
import { useHistory, useParams, useLocation } from "react-router-dom";
import CustomSnack from "../../../commonComponents/Snack/CustomSnack";
import { StyledSpaceBetween } from "../../PostJob/Components/StyledComponents";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import UploadIcon from "@mui/icons-material/Upload";
import {
  StyledTextField,
  StyledUploadEnrichButton,
} from "../../QuickMatch/Components/StyledComponents";
import { Add, Clear } from "@mui/icons-material";
import AddNoteDialog from "../../../commonComponents/AddNoteDialog/AddNoteDialog";
import Notes from "../../CandidateHub/Components/Notes";

function JobResumeDetail() {
  const location = useLocation();
  const params = useParams();
  const history = useHistory();
  const jobId = params.jobId;
  const [tabValue, setTabValue] = React.useState(0);
  const resumeTabIndex = tabValue - 1;
  const username = localStorage.getItem("username");
  const [formPrepopValue, setFormPrepopValue] = useState({
    title: "",
    industry: "",
  });
  const [resumeTab, setResumeTab] = useState([]);
  const matchedData = resumeTab[resumeTabIndex]?.details;
  //   const [matchedData, setMatchedData] = useState("");
  const [uploadResumeDialogOpen, setUploadResumeDialogOpen] = useState(false);
  const [resumeFileName, setResumeFileName] = useState("");
  const resumeInputRef = useRef(null);
  const [pageLoader, setPageLoader] = useState(false);
  const [resumeLoader, setResumeLoader] = useState(false);

  const [uploadResumeLoading, setUploadResumeLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  //Add Notes
  const [addNotesDialogOpen, setAddNotesDialogOpen] = useState(false);
  const [noteList, setNoteList] = useState([
    { note: "", interaction: "", added_by: username },
  ]);
  const [notesTableList, setNotesTableList] = useState([]);
  const [addNoteLoading, setAddNoteLoading] = useState(false);

  //Compare Resume
  const [compareModalOpen, setCompareModalOpen] = useState(false);
  const [candidate, setCandidate] = useState(null);

  const typeList = [{ label: "Phone" }, { label: "Email" }, { label: "Text" }];

  const currentDate = new Date().toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
  });

  const handleAddNotesDialogClose = () => {
    setAddNotesDialogOpen(false);
    setNoteList([{ note: "", interaction: "", added_by: username }]);
  };

  const getNotesDetatil = (id) => {
    axios
      .get(JdGetNotes(id))
      .then((res) => {
        setNotesTableList(res?.data?.data);
      })
      .catch((err) => {
        setSnack({
          open: true,
          color: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  // Handle saving the notes
  const handleSaveAddNotes = () => {
    const hasEmptyFields = noteList.some(
      (noteItem) => !noteItem.note || !noteItem.interaction
    );

    if (hasEmptyFields) {
      setSnack({
        open: true,
        color: "error",
        message: "Note and Interaction Type fields are required",
      });
      return; // Stop execution if validation fails
    }

    if (jobId) {
      setAddNoteLoading(true);
      axios
        .post(JdAddNotes(jobId), noteList)
        .then((response) => {
          if (response?.data?.success === true) {
            setSnack({
              open: true,
              color: "success",
              message: response?.data?.message,
            });
            setAddNoteLoading(false);
          } else {
            setSnack({
              open: true,
              color: "error",
              message: response?.data?.message,
            });
            setAddNoteLoading(false);
          }
          getNotesDetatil(jobId);
          setAddNotesDialogOpen(false);
          setNoteList([{ note: "", interaction: "", added_by: username }]);
        })
        .catch((err) => {
          setSnack({
            open: true,
            color: "error",
            message: err?.response?.data?.message,
          });
          setAddNoteLoading(false);
        });
    }
  };

  // Handle adding another note and interaction type
  const handleAddAnotherNote = () => {
    setNoteList([
      ...noteList,
      { note: "", interaction: "", added_by: username },
    ]); // Add new empty note object
  };

  // Handle note text change for a specific index
  const handleNoteTextChange = (index, e) => {
    const newNotes = [...noteList];
    newNotes[index].note = e.target.value; // Update note for the corresponding index
    setNoteList(newNotes);
  };

  // Handle interaction type change for a specific index
  const handlePhoneEmailTextChange = (index, value) => {
    const newNotes = [...noteList];
    newNotes[index].interaction = value?.label || ""; // Ensure you set the correct field
    setNoteList(newNotes);
  };

  const handleAddNotes = () => {
    setAddNotesDialogOpen(true);
  };

  const handleNoteDelete = (indexToDelete) => {
    let arr = [...noteList];
    arr.splice(indexToDelete, 1);
    setNoteList(arr);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    const index = newValue - 1;
    const isMatchedData = resumeTab[newValue - 1]?.details;
    const resumeId = resumeTab[newValue - 1]?.id;
    if (!isMatchedData && newValue) {
      fetchResumeData(resumeId, index);
    }
  };

  const fetchResumeData = async (resumeId, index, resumeObj) => {
    setResumeLoader(true);
    try {
      const {
        data: { data },
      } = await axios.get(getMatchedJdResumeData(resumeId));

      let arr = [...resumeTab];
      let detailObj = {
        candidate_name: data?.resume_info?.candidate_name,
        pay_rate: data?.resume_info?.pay_rate,
        location: data?.resume_info?.location,
        inferred_title: data?.resume_info?.inferred_title,
        visa_info: data?.resume_info?.visa_info,
        overall_score: data?.quick_match_score?.overall_score,
        results: data?.results || [],
        skill_calculation: data?.skill_calculation,
      };
      if (arr[index]) {
        arr[index] = {
          ...arr[index],
          details: detailObj,
        };
      } else if (resumeObj) {
        arr.push({
          ...resumeObj,
          details: detailObj,
        });
      }
      setResumeTab(arr);
      console.log(data, "matchdata");
      // setMatchedData({
      //   candidate_name: data?.resume_info?.candidate_name,
      //   pay_rate: data?.resume_info?.pay_rate,
      //   location: data?.resume_info?.location,
      //   inferred_title: data?.resume_info?.inferred_title,
      //   visa_info: data?.resume_info?.visa_info,
      //   overall_score: data?.quick_match_score?.overall_score,
      //   results: data?.results || [],
      // });
      setResumeLoader(false);
    } catch (error) {
      setSnack({
        open: true,
        color: "error",
        message: error?.response?.data?.message,
      });
      setResumeLoader(false);
    }
  };

  const fetchJobData = async () => {
    setPageLoader(true);
    try {
      const {
        data: { data },
      } = await axios.get(getJobData(jobId));

      const certificates = data?.job_details?.certificates?.flatMap((item) =>
        item.content
          .filter((value) => value.length > 0) // Filter out empty strings
          .map((value) => ({ name: item.name, value }))
      );

      const languages = data?.job_details?.languages?.flatMap((item) =>
        item.content
          .filter((value) => value.length > 0) // Filter out empty strings
          .map((value) => ({ name: item.name, value }))
      );
      const employer = data?.job_details?.employer_targets?.flatMap((item) =>
        item.content
          .filter((value) => value.length > 0) // Filter out empty strings
          .map((value) => ({ name: item.name, value }))
      );
      setFormPrepopValue({
        title: data?.job_details?.job_title,
        industry: data?.job_details?.industry,
        payRate:
          typeof data?.job_details?.pay_rate === "string"
            ? data.job_details.pay_rate
            : null,
        managementLevel: data?.job_details?.management_level,
        employmentType:
          typeof data?.job_details?.employment === "string"
            ? data.job_details.employment
            : null,
        location: data?.job_details?.location,
        startDate: data?.job_details?.start_date,
        endDate: data?.job_details?.end_date,
        occupationalClassification:
          data?.job_details?.occupational_classification,
        requestId: data?.job_details?.req_id,
        shift: data?.job_details?.shift,
        reportsTo: data?.job_details?.reports_to,
        buDepartmentTeam: data?.job_details["bu/department/team"],
        hardSkills: data?.job_details?.extracted_hard_skills,
        softSkills: data?.job_details?.extracted_soft_skills,
        recommendHardSkills: data?.job_details?.relevant_hard_skills,
        recommendSoftSkills: data?.job_details?.relevant_soft_skills,

        locationTargets: data?.job_details?.location_targets?.map((elt) => ({
          value: elt,
        })),
        employerTargets: employer,
        certificates: certificates,
        education:
          typeof data?.job_details?.education === "string"
            ? data?.job_details?.education
            : null,
        languages: languages,
        visaStatus: data?.job_details["visa/employment_needs"]?.visa_status,
        visaContent: data?.job_details["visa/employment_needs"]?.content,
        govtSecurityClearance: data?.job_details?.govt_security_clearance,
        dei: data?.job_details?.dei,
        relocation_required: data?.job_details?.relocation_required,
        score: data?.job_details?.score,
        insights: data?.job_details?.insights,
        inferredTitle: data?.job_details?.inferred_title,
        companyProfile: data?.job_details?.company_profile,
        company_name: data?.job_details?.company_name,
        scoreLabel: data?.job_details?.score_label,
        interviewType: data?.job_details?.interview_type || [],
      });
      console.log(data?.job_details?.resumes, "data?.job_details?.resumes");
      setResumeTab(data?.resumes || []);
      setPageLoader(false);
    } catch (error) {
      setSnack({
        open: true,
        color: "error",
        message: error?.response?.data?.message,
      });
      setPageLoader(false);
    }
  };
  useEffect(() => {
    fetchJobData();
    getNotesDetatil(jobId);
    //resume
  }, [jobId]);

  const handleAddHardSkill = () => {
    const newSkill = {
      skill: "",
      experience_in_yrs: 0,
      criticality_score: 0,
    };
    //   setHardSkills([...hardSkills, newSkill]);
    let arr = [...formPrepopValue?.hardSkills, newSkill];
    setFormPrepopValue({
      ...formPrepopValue,
      hardSkills: arr,
    });
  };

  const handleAddSoftSkill = () => {
    const newSkill = {
      skill: "",
      criticality_score: 0,
    };
    let arr = [...formPrepopValue?.softSkills, newSkill];
    setFormPrepopValue({
      ...formPrepopValue,
      softSkills: arr,
    });
    //   setSoftSkills([...softSkills, newSkill]);
  };

  const handleRemoveSkill = (index) => {
    let arr = [...formPrepopValue?.hardSkills];
    arr.splice(index, 1);
    setFormPrepopValue({
      ...formPrepopValue,
      hardSkills: arr,
    });
  };

  const handleSliderChange = (index, newValue, type) => {
    if (type === "hard") {
      let arr = [...formPrepopValue?.hardSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        hardSkills: arr,
      });
    } else if (type === "soft") {
      let arr = [...formPrepopValue?.softSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        softSkills: arr,
      });
    } else if (type === "recommendHard") {
      let arr = [...formPrepopValue?.recommendHardSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        recommendHardSkills: arr,
      });
    } else {
      let arr = [...formPrepopValue?.recommendSoftSkills];
      arr[index].criticality_score = newValue;
      setFormPrepopValue({
        ...formPrepopValue,
        recommendSoftSkills: arr,
      });
    }
  };

  const handleMultiSelectChange = (name, val) => {
    if (typeof val === "string") {
      setFormPrepopValue({
        ...formPrepopValue,
        [name]: val,
      });
    } else if (val && val.inputValue) {
      // Create a new value from the user input
      setFormPrepopValue({
        ...formPrepopValue,
        [name]: val.inputValue,
      });
    } else {
      // setFieldValue("preferred", newValue);
      setFormPrepopValue({
        ...formPrepopValue,
        [name]: val,
      });
    }
  };

  const handleUploadResume = () => {
    // let responseId = "fb933ef1-4f27-4295-8aa6-0544f1baeb7c";
    // let arr = [...resumeTab];

    // let resumeObj = {
    //   id: responseId,
    //   name: "Person - 3",
    //   score: "45",
    // };
    // arr.push(resumeObj);
    // let currentResumeIndex = arr.length;
    // setResumeTab(arr);
    // fetchResumeData(responseId, currentResumeIndex, resumeObj);
    // setTabValue(currentResumeIndex);
    setUploadResumeDialogOpen(true);
  };

  const handleUploadResumeFile = () => {
    setUploadResumeLoading(true);
    const formData = new FormData();
    if (resumeInputRef.current.files.length > 0) {
      formData.append("resume_file", resumeInputRef.current.files[0]);
    }
    formData.append("domain", "");
    formData.append("country", "");
    formData.append("id", jobId);
    axios
      .post(uploadJdResume(), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        if (response?.data?.success === true) {
          //   setSnack({
          //     open: true,
          //     color: "success",
          //     message: response?.data?.message,
          //   });
          let responseId = response?.data?.data?.id;
          let arr = [...resumeTab];

          let resumeObj = {
            id: responseId,
            name: response?.data?.data?.resume_info?.candidate_name,
            score: response?.data?.data?.quick_match_score?.overall_score,
          };
          arr.push(resumeObj);
          let currentResumeIndex = arr.length;
          setResumeTab(arr);
          fetchResumeData(responseId, currentResumeIndex, resumeObj);
          setTabValue(currentResumeIndex);
          setUploadResumeLoading(false);
          setUploadResumeDialogOpen(false);
          setResumeFileName(""); // Clear the file name
          if (resumeInputRef.current) {
            resumeInputRef.current.value = ""; // Clear the file input
          }
        }
      })
      .catch((err) => {
        setUploadResumeLoading(false);
        setSnack({
          open: true,
          color: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleUploadResumeDialogClose = () => {
    setUploadResumeDialogOpen(false);
    setResumeFileName(""); // Clear the file name
    if (resumeInputRef.current) {
      resumeInputRef.current.value = ""; // Clear the file input
    }
  };

  const handleResumeFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setResumeFileName(file.name); // Update file name in text field
    }
  };

  const handleClearResumeFileName = () => {
    setResumeFileName(""); // Clear the file name
    if (resumeInputRef.current) {
      resumeInputRef.current.value = ""; // Clear the file input
    }
  };

  const handleUploadResumeDialog = () => {
    if (resumeInputRef.current) {
      resumeInputRef.current.click();
    }
  };

  const handleBack = () => {
    history.push(`/home/active-jobs`);
  };

  const handleViewResume = () => {
    const isMatchedData = resumeTab[tabValue - 1]?.details;
    const resumeId = resumeTab[tabValue - 1]?.id;
    // if (!isMatchedData && tabValue) {
    history.push(`/home/candidate-hub/get-detail?id=${resumeId}`);
    // }
  };

  const handleCompareResume = () => setCompareModalOpen(true);
  const handleCompareResumeClose = () => {
    setCompareModalOpen(false);
    setCandidate(null);
  };
  const handleCompareResumeSubmit = () => {
    const resumeId = resumeTab[tabValue - 1]?.id;
    console.log(candidate?.id, "candidate");
    console.log(resumeId, "rid");
    history.push(
      `/home/active-jobs/${resumeId}/${candidate?.id}/compare-resume-details`
    );
  };

  const handleCandidateChange = (e, newVal) => {
    setCandidate(newVal);
  };

  const selectedId = resumeTab[tabValue - 1]?.id;

  const filteredResumeTab = resumeTab.filter((item) => item.id !== selectedId);

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          item
          xs={12}
          sx={{
            position: "sticky",
            top: 0, // Adjust if needed
            zIndex: 1000, // Ensure it stays on top of other content when scrolling
            backgroundColor: "white", // Optional: Keep it visible with a background
          }}
        >
          <StyledSpaceBetween>
            <StyledButton
              onClick={handleBack}
              startIcon={<KeyboardBackspaceIcon />}
            >
              {"Back"}
            </StyledButton>
            <StyledButton
              onClick={handleUploadResume}
              startIcon={<UploadIcon />}
            >
              {"Upload Resume"}
            </StyledButton>
          </StyledSpaceBetween>
        </Grid>

        {pageLoader ? (
          Array.from(new Array(12)).map((el, ind) => (
            <Grid item xs={12} sm={4} key={`key-${ind}`}>
              <Skeleton
                key={`key-${ind.toString()}`}
                width={"100%"}
                height={100}
              />
            </Grid>
          ))
        ) : (
          <>
            <Grid
              item
              xs={12}
              sx={{
                position: "sticky",
                top: "50px", // Below the button section, adjust as needed
                zIndex: 1000,
                backgroundColor: "white",
              }}
            >
              <Box
                sx={{
                  width: "100%", // Full width for the box
                  overflowX: "auto", // Ensure the Tabs can scroll horizontally without affecting layout
                  display: "block", // Block display to avoid flex issues
                  minHeight: "60px", // Optional: Ensures minimum space for the Tabs
                }}
              >
                <JobResumeTab
                  tabValue={tabValue}
                  handleTabChange={handleTabChange}
                  resumeTab={resumeTab}
                  formPrepopValue={formPrepopValue}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                flexGrow: 1,
                overflowY: "auto",
                maxHeight: "calc(100vh - 260px)",
              }}
            >
              {tabValue === 0 ? (
                <>
                  <Grid container spacing={3}>
                    {/* <Grid item xs={12}>
                      <Typography>{"Job Details"}</Typography>
                    </Grid> */}

                    <Grid item xs={12}>
                      <FormPrePop formPrepopValue={formPrepopValue} />
                    </Grid>

                    <Grid item xs={12}>
                      <HardSoftSkill
                        formPrepopValue={formPrepopValue}
                        handleAddHardSkill={handleAddHardSkill}
                        handleAddSoftSkill={handleAddSoftSkill}
                        handleRemoveSkill={handleRemoveSkill}
                        handleSliderChange={handleSliderChange}
                        isDelete={false}
                      />
                    </Grid>

                    <Grid item xs={12} mt={3}>
                      <Education
                        formPrepopValue={formPrepopValue}
                        handleMultiSelectChange={handleMultiSelectChange}
                      />
                    </Grid>

                    <Grid item xs={12} mt={3}>
                      <OverallChart formPrepopValue={formPrepopValue} />
                    </Grid>

                    {notesTableList?.length ? (
                      <Grid item xs={12}>
                        <Notes notesTableList={notesTableList} />
                      </Grid>
                    ) : null}

                    <Grid item xs={12}>
                      <StyledButton
                        variant="contained"
                        endIcon={<Add />}
                        onClick={handleAddNotes}
                      >
                        {"Add Notes"}
                      </StyledButton>
                    </Grid>

                    {/* Notes Dialog */}
                    <AddNoteDialog
                      handleAddNotesDialogClose={handleAddNotesDialogClose}
                      handleSaveAddNotes={handleSaveAddNotes}
                      addNotesDialogOpen={addNotesDialogOpen}
                      noteList={noteList}
                      handleAddAnotherNote={handleAddAnotherNote}
                      handleNoteTextChange={handleNoteTextChange}
                      handlePhoneEmailTextChange={handlePhoneEmailTextChange}
                      handleNoteDelete={handleNoteDelete}
                      username={username}
                      notesTableList={notesTableList}
                      addNoteLoading={addNoteLoading}
                      currentDate={currentDate}
                      typeList={typeList}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container spacing={3}>
                    {resumeLoader ? (
                      Array.from(new Array(12)).map((el, ind) => (
                        <Grid item xs={12} sm={4} key={`key-${ind}`}>
                          <Skeleton
                            key={`key-${ind.toString()}`}
                            width={"100%"}
                            height={100}
                          />
                        </Grid>
                      ))
                    ) : (
                      <>
                        {/* <Grid item xs={12}>
                          <Typography>{"Resume Details"}</Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                          <Profile
                            matchedData={matchedData}
                            handleViewResume={handleViewResume}
                            handleCompareResume={handleCompareResume}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InteractionHistory
                            matchedData={matchedData}
                            isBack={false}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )}

        <CustomSnack
          open={snack.open}
          color={snack.color}
          message={snack.message}
          onClose={handleSnackClose}
        />
      </Grid>

      {/* upload resume dailog */}
      <Dialog
        maxWidth="md"
        fullWidth
        open={uploadResumeDialogOpen}
        onClose={handleUploadResumeDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            padding: "20px",
            borderRadius: "28px",
            backgroundColor: "#f3e9ff",
          },
        }}
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ fontWeight: 700 }}>
                {"Upload Resume"}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid
                container
                spacing={4}
                justifyContent="start"
                alignItems="center"
                sx={{ mb: 3 }}
              >
                <Grid item xs={12} md={7}>
                  <StyledTextField
                    variant="outlined"
                    fullWidth
                    size={"large"}
                    label="Upload a resume "
                    placeholder="Upload a resume"
                    value={resumeFileName}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {resumeFileName && (
                            <IconButton onClick={handleClearResumeFileName}>
                              <Clear />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <StyledUploadEnrichButton
                    variant="contained"
                    component="span"
                    onClick={handleUploadResumeDialog}
                    size={"large"}
                  >
                    {"Upload Candidate Resume"}
                    <input
                      ref={resumeInputRef}
                      type="file"
                      hidden
                      onChange={handleResumeFileChange}
                      name="resumeFile"
                      id="resumeFile"
                    />
                  </StyledUploadEnrichButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ display: "flex", justifyContent: "end" }}>
              <StyledFlexRowView>
                <StyledButton
                  variant="contained"
                  onClick={handleUploadResumeDialogClose}
                >
                  Cancel
                </StyledButton>
                <StyledButton
                  variant="contained"
                  onClick={handleUploadResumeFile}
                  startIcon={
                    uploadResumeLoading ? (
                      <CircularProgress color="inherit" size={15} />
                    ) : null
                  }
                  disabled={uploadResumeLoading}
                >
                  Submit
                </StyledButton>
              </StyledFlexRowView>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {/* compare resume modal */}
      <Modal
        keepMounted
        open={compareModalOpen}
        // onClose={handleCompareResumeClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 450,
            bgcolor: "background.paper",
            border: "1px solid #000",
            boxShadow: 24,
            p: 4,
            borderRadius: "16px",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "16px" }}
          >
            <Typography
              id="keep-mounted-modal-title"
              variant="h5"
              component="h2"
              fontWeight={"bold"}
            >
              Compare Resume
            </Typography>

            <Typography>
              Select a candidate to compare their resume with{" "}
              <b>{resumeTab[tabValue - 1]?.name}</b>'s.
            </Typography>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={filteredResumeTab || []}
              // getOptionLabel={(option) => option.name}
              getOptionLabel={(option) => {
                if (typeof option === "object" && option !== null) {
                  return option.name;
                }
                return option;
              }}
              value={candidate}
              onChange={handleCandidateChange}
              renderInput={(params) => (
                <TextField {...params} label="Select a Candidate Name" />
              )}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "12px",
              }}
            >
              <StyledButton onClick={handleCompareResumeClose}>
                Cancel
              </StyledButton>
              <StyledButton onClick={handleCompareResumeSubmit} autoFocus>
                Compare
              </StyledButton>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default JobResumeDetail;
