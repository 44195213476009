import React from "react";
import { Box, Divider, Grid, TextField, Typography } from "@mui/material";
import deleteIco from "../../../images/svgs/delete.svg";
import "../Style.css";
import AddIcon from "@mui/icons-material/Add";
import CustomSwitch from "../../../commonComponents/Switch/CustomSwitch";
import {
  StyledCheckBox,
  StyledSlider,
} from "../../../commonComponents/StyledComponents";
import {
  RangeContainer,
  RecommendContainer,
  SkillTitle,
  StyledBoldTypo,
  StyledSpaceBetween,
} from "./StyledComponents";
import { StyledButton } from "../../CandidateHub/Components/StyledComponents";

function HardSoftSkill({
  formPrepopValue,
  handleAddHardSkill,
  handleAddSoftSkill,
  handleRemoveSkill,
  handleSliderChange,
  isDelete,
}) {
  console.log(formPrepopValue, "hh");

  return (
    <Grid container spacing={3}>
      {/* Hard Skill Section */}
      <Grid item xs={12} md={6}>
        <SkillTitle>
          <StyledBoldTypo isCenter>{"Hard Skill"}</StyledBoldTypo>
          <CustomSwitch sx={{ m: 1 }} checked={true} />
        </SkillTitle>

        {formPrepopValue?.hardSkills?.map((skill, index) => (
          <RangeContainer key={index}>
            <Box sx={{ width: "100%" }}>
              <StyledSpaceBetween>
                <Typography>{skill?.skill}</Typography>
                <Typography>{"Critical"}</Typography>
              </StyledSpaceBetween>
              <StyledSlider
                defaultValue={skill?.criticality_score}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(event, newValue) =>
                  handleSliderChange(index, newValue, "hard")
                }
                min={0}
                max={10}
                disabled={true}
              />
            </Box>
            <div>
              {index === 0 ? <Typography>{"Years"}</Typography> : null}
              <TextField
                id={`outlined-controlled-hard-${skill.id}`}
                label=""
                placeholder=""
                size="small"
                sx={{ width: "50px" }}
                value={skill.experience_in_yrs}
              />
            </div>
            {isDelete ? (
              <img
                className="deleteImg"
                src={deleteIco}
                alt="delete"
                onClick={() => handleRemoveSkill(index)}
                style={{ cursor: "pointer" }}
              />
            ) : null}
          </RangeContainer>
        ))}

        {formPrepopValue?.recommendHardSkills?.length ? (
          <RecommendContainer>
            <Divider style={{ display: "flex", flexGrow: 1 }}>
              {"Recommended Skills"}
            </Divider>
            <StyledCheckBox checked={true} />
          </RecommendContainer>
        ) : null}

        {formPrepopValue?.recommendHardSkills?.map((skill, index) => (
          <RangeContainer key={skill.id}>
            <Box sx={{ width: "100%" }}>
              <StyledSpaceBetween>
                <Typography>{skill.skill}</Typography>
                <Typography>{"Critical"}</Typography>
              </StyledSpaceBetween>
              <StyledSlider
                defaultValue={skill.criticality_score}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(event, newValue) =>
                  handleSliderChange(index, newValue, "recommendHard")
                }
                min={0}
                max={10}
                disabled={true}
              />
            </Box>
            <div>
              {index === 0 ? <Typography>{"Years"}</Typography> : null}
              <TextField
                id={`outlined-controlled-hard-${skill.id}`}
                label=""
                placeholder=""
                size="small"
                sx={{ width: "50px" }}
                value={skill.experience_in_yrs}
              />
            </div>
            {isDelete ? (
              <img
                className="deleteImg"
                src={deleteIco}
                alt="delete"
                // onClick={() => handleRemoveSkill(skill.id, "hard")}
                style={{ cursor: "pointer" }}
              />
            ) : null}
          </RangeContainer>
        ))}

        {isDelete ? (
          <div>
            <StyledButton
              onClick={handleAddHardSkill}
              variant="contained"
              endIcon={<AddIcon />}
            >
              {"Add"}
            </StyledButton>
          </div>
        ) : null}
      </Grid>

      {/* Soft Skill Section */}
      <Grid item xs={12} md={6}>
        <SkillTitle>
          <StyledBoldTypo isCenter>{"Soft Skill"}</StyledBoldTypo>
          <CustomSwitch sx={{ m: 1 }} checked={true} />
        </SkillTitle>

        {formPrepopValue?.softSkills?.map((skill, index) => (
          <RangeContainer key={skill.id}>
            <Box sx={{ width: "100%" }}>
              <StyledSpaceBetween>
                <Typography>{skill.skill}</Typography>
                <Typography>{"Critical"}</Typography>
              </StyledSpaceBetween>
              <StyledSlider
                defaultValue={skill.criticality_score}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(event, newValue) =>
                  handleSliderChange(index, newValue, "soft")
                }
                min={0}
                max={10}
                disabled={true}
              />
            </Box>
            {isDelete ? (
              <img
                className="deleteImg"
                src={deleteIco}
                alt="delete"
                // onClick={() => handleRemoveSkill(index)}
                style={{ cursor: "pointer" }}
              />
            ) : null}
          </RangeContainer>
        ))}

        {formPrepopValue?.recommendSoftSkills?.length ? (
          <RecommendContainer>
            <Divider style={{ display: "flex", flexGrow: 1 }}>
              {"Recommended Skills"}
            </Divider>
            <StyledCheckBox checked={true} />
          </RecommendContainer>
        ) : null}

        {formPrepopValue?.recommendSoftSkills?.map((skill, index) => (
          <RangeContainer key={skill.id}>
            <Box sx={{ width: "100%" }}>
              <StyledSpaceBetween>
                <Typography>{skill.skill}</Typography>
                <Typography>{"Critical"}</Typography>
              </StyledSpaceBetween>
              <StyledSlider
                defaultValue={skill.criticality_score}
                aria-label="Default"
                valueLabelDisplay="auto"
                onChange={(event, newValue) =>
                  handleSliderChange(index, newValue, "recommendSoft")
                }
                min={0}
                max={10}
                disabled={true}
              />
            </Box>
            {isDelete ? (
              <img
                className="deleteImg"
                src={deleteIco}
                alt="delete"
                // onClick={() => handleRemoveSkill(index)}
                style={{ cursor: "pointer" }}
              />
            ) : null}
          </RangeContainer>
        ))}
        {isDelete ? (
          <div>
            <StyledButton
              onClick={handleAddSoftSkill}
              variant="contained"
              endIcon={<AddIcon />}
            >
              {"Add"}
            </StyledButton>
          </div>
        ) : null}
      </Grid>
    </Grid>
  );
}

export default HardSoftSkill;
