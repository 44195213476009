import { Grid, Typography } from "@mui/material";
import React from "react";
import ScoringRadarChart from "./ScoringRadarChart";

const RatingChart = () => {
  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6" align="center" fontWeight={"bold"}>
          {"Compare to other candidates"}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ScoringRadarChart />
      </Grid>
    </Grid>
  );
};

export default RatingChart;
