//////////////////////////////////////////////////////////////////////////////////////////
//                        API Endpoints
//////////////////////////////////////////////////////////////////////////////////////////

export const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
// "http://localhost:8000";
// "https://qa-api.talentium.ai";
// "https://trim-api.talentium.ai";
// User authentication API
export const userAuth_service = "/auth/";

// JWT API
export const userAuth_API = "/token/";
export const userInfo_API = "/customers/userinfo/";
export const userAuthRefresh_API = "/token/refresh/";

// Create new account API
export const createNewAccount_API = "/customers/create-account/";

// Create new account API
export const contactUs_API = "/request-demo/";

// Extract skills API
export const extractSkills_API = "/intake/extract-skills-unlimited/";
export const update_skills_api = (jobId) => `/intake/jobdescription/${jobId}/`;
export const extractSkills_API_NOTLOGIN = "/intake/extract-skills-ratelimit/";

// Parse Resume API

export const parseResume_API = "/resume/extract-resume/";

export const inferResume_API = "/resume/infer-resume/";

// Payment gateway
export const recurringPayment_API = "http://localhost:8000/customers/payment/";

// Skill Master
export const getAllSkills = (limit, offset, search) =>
  `/intake/skills/?limit=${limit}&offset=${offset}&search=${search}`;
export const updateSkills = (id) => `/intake/skills/${id}/`;
export const deleteSkills = (id) => `/intake/skills/${id}/`;
export const addSkills = `/intake/skills/`;
// Competitor Company
export const getAllCompetitorCompany = (limit, offset, search) =>
  `/intake/competitor-company/?limit=${limit}&offset=${offset}&search=${search}`;
export const updateCompetitorCompany = (id) =>
  `/intake/competitor-company/${id}/`;
export const deleteCompetitorCompany = (id) =>
  `/intake/competitor-company/${id}/`;
export const addCompetitorCompany = `/intake/competitor-company/`;
export const getJobDesAndWeightage = (jobCode) =>
  `/intake/jobdescription/job-code/${jobCode}/`;
export const getClientList = `/intake/client-list/`;
// Job Posting

export const getAllJobPosting = () => `/job-posting/extract/`;
export const postJobDetail = () => `/job-posting/job-detail/`;
// Applicants
export const getApplicantsByJobCode = (jobCode) =>
  `/job-posting/applicant/${jobCode}/`;
export const getApplicants = () => `/job-posting/applicants/`;
export const postResumeByJobCodeApplicantId = `/resume/incoming-resume/`;
export const getSkillList = `/resume-compare/get-skill-list/`;
export const getRoleList = `/resume-compare/get-role-list/`;
//compare resume
export const compareResumeApi = `/resume-compare/compare-resume/`;

// Job details
export const updateJobDetail = (id) => `/job-posting/job-detail/${id}/`;

// Qualify
export const getAllQualify = (jobCode) => `/resume/${jobCode}/resumes/`;

export const postSelectedIds = (jobCode) =>
  `/job-posting/applicant/${jobCode}/select/`;

export const postPhoneQuestionAnswer = () =>
  `/job-posting/job-detail/question/answer/`;
export const getPhoneQuestionAnswer = (applicant_id, job_code) =>
  `/job-posting/job-detail/question/answer/?applicant_id=${applicant_id}&job_code=${job_code}`;
export const postCoverSheetFormQuestionAnswer = () =>
  `/job-posting/job-detail/coversheetquestion/answer/`;
export const applicantClientAccess = () => `/job-posting/applicant-access/`;
export const getCoverSheetFormQuestionAnswer = (applicant_id, job_code) =>
  `/job-posting/job-detail/coversheetquestion/answer/?applicant_id=${applicant_id}&job_code=${job_code}`;
export const getCoverSheetQuestionAnswer = () =>
  `/job-posting/job-detail/coversheetquestion/answer/`;

export const getApplicantByID = (id) =>
  `/job-posting/applicant-retrieve/${id}/`;
export const postApplicantSession = (id) =>
  `/job-posting/applicant/session/${id}/`;

export const getAllCoversation = (job_code, applicant_id) =>
  `/chatbot/${job_code}/${applicant_id}/chats/`;
export const sendUserMessage = (job_code, applicant_id) =>
  `/chatbot/${job_code}/${applicant_id}/chats/`;
export const getAllCoverSheetChats = (job_code, applicant_id) =>
  `/chatbot/${job_code}/${applicant_id}/coversheetchats/`;
export const sendUserCoverSheetChats = (job_code, applicant_id) =>
  `/chatbot/${job_code}/${applicant_id}/coversheetchats/`;

export const getReportData = () => ``;
export const getApplicantSession = (applicantSessionId) =>
  `/job-posting/applicant/session/${applicantSessionId}/`;

export const uploadExcelFileintake = () => `/intake/intake-form/`;
export const downloadExcelFileIntake = (id) =>
  `/intake/intake-form/${id}/export/`;
export const getAllJobIntakeForm = () => `/intake/intake-form/`;
export const getJobIntakeById = (id) => `/intake/intake-form/${id}/`;
export const getAllClients = () => `/intake/intake-client`;

export const getAllIntakeList = () => `/intake/skills/`;
export const getAllCompetitorCompanyList = () => `/intake/competitor-company/`;

export const updateJobIntakeForm = (id) => `/intake/intake-form/${id}/`;
export const deleteIntakeById = (id) => `/intake/intake-form/${id}/`;

export const getAllRecommendation = (jobCode) =>
  `/resume/recommend/${jobCode}/`;
export const recommendForInterview = () => `/resume/qualify-candidate/`;

export const getModuleCount = () => `/resume/status-count/`;

export const getAllTestList = () => `/invitation-tests/test-list/`;

export const downloadQualifyReport = () => `/resume/qualify-report/`;
export const downloadRecommendReport = () => `/resume/recommend-report/`;

export const sendToManager = () => `/resume/qualify-email/`;
export const sendForApproval = () => `/job-posting/approval-mail/`;

export const emailSetting = () => `/job-posting/email-setting/`;

export const clientEmailSetting = () => `/job-posting/client-email-setting/`;

export const recruiterEmailSetting = () =>
  `/job-posting/recruiter-email-setting/`;

// Intake form
export const deleteMultipleIntakeForm = () => `/intake/intake-form/delete/`;
export const addIntakeForm = () => `/intake/intake-form/`;
export const intakeClientAddEmail = () => `/intake/intakeclient-add/`;
export const intakeSendNotify = () => `/intake/send-notify/`;
export const downloadIntakeFormTemplate = () => `/intake/download-intake/`;
export const downloadIntakeFormExcelTemplate = () =>
  `/intake/download-excel-intake/`;

// Job posting
export const deleteJobPostingDetail = (id) => `/job-posting/job-detail/${id}/`;
export const updateJobPostingStatus = (id) => `/job-posting/job-detail/${id}/`;
export const getAllJobClients = (id) => `/job-posting/client-list/`;
// Funnel Page Applicant
export const deleteFunnelPageApplicant = () => `/job-posting/applicant-delete/`;
export const updateFunnelStatus = (job_code, id) =>
  `/job-posting/applicant/${job_code}/${id}/`;
export const applyJobToCandidate = () => `/resume/apply-job/`;

export const updateQualifyStatus = (job_code, id) =>
  `/job-posting/applicant/${job_code}/${id}/`;

export const deleteApplicantPageApplicant = (id) => `/resume/applicants/${id}/`;

export const downloadResume = () => `/job-posting/resume-download/`;

export const rejectApplicant = () => `/job-posting/reject-applicant/`;

export const restoreCandidate = (job_code, id) =>
  `/job-posting/applicant/${job_code}/${id}/`;

export const migrateCandidate = () => `/resume/applicant-migrate/`;

export const getAllJob = () => `/job-posting/job-detail-get/`;

export const updateInterviewStatus = (job_code, id) =>
  `/resume/recommend/${job_code}/${id}/`;

export const qualifyExportOrSendToManager = () => `/resume/submission-report/`;

export const reportExportOrSendEmail = () => `/resume/report-module/`;

export const updateSubmissionReport = () => `/resume/submission-update/`;

// Client Interview
export const moveToInterview = () => `/resume/recommend-update/`;

// Forgot & Reset Password
export const resetPasswordRequest = () => `/customers/reset_password/`;
export const resetPasswordTokenVerification = (id, token) =>
  `/customers/password-reset-confirm/${id}/${token}/`;
export const resetPassword = () => `/customers/setpassword/`;

// Contact Support
export const getContactSupportMail = () => `/job-posting/support-mail/`;
export const postContactSupport = () => `/job-posting/support-mail/`;

// Apply Job
export const applyJobAuthenticate = () => `/resume/apply-job/`;

// Panel Settings
export const panelSetting = () => `/job-posting/panel-setting/`;

// Applicant History
export const getApplicantHistory = () => `/job-posting/applicant-history/`;

// Interview Schedule
export const getInterviewApplicationSession = (applicantSessionId) =>
  `/job-posting/applicant/interviewsession/${applicantSessionId}/`;
export const postInterviewPhoneQuestionAnswer = () =>
  `/job-posting/interview/question/answer/`;
export const postApplicantInterviewSession = (id) =>
  `/job-posting/applicant/interviewsession/${id}/`;
export const getInterviewPhoneQuestionAnswer = (applicant_id, job_code) =>
  `/job-posting/interview/question/answer/?applicant_id=${applicant_id}&job_code=${job_code}`;
export const getAllInterviewConversation = (job_code, applicant_id) =>
  `/chatbot/${job_code}/${applicant_id}/interviewchats/`;
export const sendInterviewUserMessage = (job_code, applicant_id) =>
  `/chatbot/${job_code}/${applicant_id}/interviewchats/`;

// Application History Report
export const updateApplicationHistoryReport = () =>
  `/resume/application-history-update/`;

// Grammar Checker
export const getResumeDetails = () => `/resume/resume-insights/`;
export const updateResumeContent = () => `/resume/replace_text/`;

// Score settings
export const scoreSetting = () => `/job-posting/scoring-settings/`;

// Skill Hierarchy
export const getSkillHierarchy = () => `/intake/hierarchy/`;
export const getSingleSkillHierarchy = (id) => `intake/hierarchy/${id}/`;
export const createSkillHierarchy = () => `/intake/hierarchy/`;
export const updateSkillHierarchy = (id) => `/intake/hierarchy/${id}/`;
export const deleteSkillHierarchy = (id) => `/intake/hierarchy/${id}/`;

// Educational Institutes Hierarchy
export const getEducationInstitutesHierarchy = () =>
  `/intake/education-institutes/`;
export const getSingleEducationInstitutesHierarchy = (id) =>
  `/intake/education-institutes/${id}/`;
export const createEducationInstitutesHierarchy = () =>
  `/intake/education-institutes/`;
export const updateEducationInstitutesHierarchy = (id) =>
  `/intake/education-institutes/${id}/`;
export const deleteEducationInstitutesHierarchy = (id) =>
  `/intake/education-institutes/${id}/`;
// Preferred Company Hierarchy
export const getPreferredCompanyHierarchy = () =>
  `/intake/competitor-preferred/`;
export const getSinglePreferredCompanyHierarchy = (id) =>
  `/intake/competitor-preferred/${id}/`;
export const createPreferredCompanyHierarchy = () =>
  `/intake/competitor-preferred/`;
export const updatePreferredCompanyHierarchy = (id) =>
  `/intake/competitor-preferred/${id}/`;
export const deletePreferredCompanyHierarchy = (id) =>
  `/intake/competitor-preferred/${id}/`;

// Hobbies Hierarchy
export const getHobbiesHierarchy = () => `/intake/hobbie/`;
export const getSingleHobbiesHierarchy = (id) => `/intake/hobbie/${id}/`;
export const createHobbiesHierarchy = () => `/intake/hobbie/`;
export const updateHobbiesHierarchy = (id) => `/intake/hobbie/${id}/`;
export const deleteHobbiesHierarchy = (id) => `/intake/hobbie/${id}/`;

// Domains Hierarchy
export const getDomainsHierarchy = () => `/intake/domain/`;
export const getSingleDomainssHierarchy = (id) => `/intake/domain/${id}/`;
export const createDomainsHierarchy = () => `/intake/domain/`;
export const updateDomainsHierarchy = (id) => `/intake/domain/${id}/`;
export const deleteDomainsHierarchy = (id) => `/intake/domain/${id}/`;

// User
export const getUser = () => `/customers/users/`;
export const getSingleUser = (id) => `/customers/users/${id}/`;
export const createUser = () => `/customers/users/`;
export const updateUser = (id) => `/customers/users/${id}/`;
export const deleteUser = (id) => `/customers/users/${id}/`;

//talairo ai
//JD Parse
export const uploadJobDataFile = () => `/intake/jobdata/`;
export const getJobData = (id) => `/intake/jobdata/${id}`;
export const JdAddNotes = (id) => `/intake/add-note-jd/${id}/`;
export const JdGetNotes = (id) => `intake/get-notes-jd/${id}`;

//Resume Parse
export const uploadResume = () => `/resume/resumedata/`;
export const getResumeData = (id) => `/resume/resumedata/${id}`;
export const addNotes = (id) => `/resume/add-note/${id}/`;
export const getNotes = (id) => `resume/get-notes/${id}`;

//Quick Match
export const uploadJdResume = () => `/resume/match/resume/jd`;
export const getMatchedJdResumeData = (id) => `/resume/match/resume/jd/${id}`;
export const compareResume = (firstResumeId, secondResumeId) =>
  `/resume/compare-resume/${firstResumeId}/${secondResumeId}`;

//Active Jobs
export const getActiveJobList = () => `/intake/job-list`;
