import React, { useState } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";

// Sample data
const data = [
  { subject: "Mobile analytics", A: 85, B: 10, fullMark: 100 },
  { subject: "Display advertising", A: 90, B: 70, fullMark: 100 },
  { subject: "Email", A: 75, B: 60, fullMark: 100 },
  { subject: "Nonbranded SEO", A: 60, B: 30, fullMark: 100 },
  { subject: "YouTube", A: 65, B: 10, fullMark: 100 },
  { subject: "Instagram", A: 70, B: 80, fullMark: 100 },
  { subject: "Facebook", A: 80, B: 40, fullMark: 100 },
  { subject: "Transact", A: 95, B: 65, fullMark: 100 },
  { subject: "Guided selling", A: 55, B: 90, fullMark: 100 },
  { subject: "Content and product pages", A: 80, B: 10, fullMark: 100 },
  { subject: "Traffic and engagement", A: 65, B: 40, fullMark: 100 },
  { subject: "Site speed", A: 85, B: 10, fullMark: 100 },
];

// Custom tick component for PolarAngleAxis
const CustomPolarAngleAxisTick = (props) => {
  const { payload, x, y, textAnchor } = props;

  // Function to break text into lines
  const breakTextIntoLines = (text, maxLineWidth) => {
    const words = text.split(" ");
    let lines = [];
    let currentLine = "";

    words.forEach((word) => {
      const testLine = currentLine + word + " ";
      const textLength = testLine.length; // Simple length check

      // Assuming a fixed width for each character
      const averageCharWidth = 6; // Average character width in pixels
      const lineWidth = textLength * averageCharWidth;

      if (lineWidth > maxLineWidth && currentLine) {
        lines.push(currentLine.trim());
        currentLine = word + " ";
      } else {
        currentLine = testLine;
      }
    });

    lines.push(currentLine.trim());
    return lines;
  };

  // Fixed maxLineWidth for given width
  const maxLineWidth = 80; // Adjust this value as needed
  const textLines = breakTextIntoLines(payload.value, maxLineWidth);

  return (
    <text x={x} y={y} textAnchor={textAnchor} fontSize={12} fill="#666">
      {textLines.map((line, index) => (
        <tspan x={x} dy={index === 0 ? 0 : 15} key={index}>
          {line}
        </tspan>
      ))}
    </text>
  );
};

// Custom tick component for PolarRadiusAxis
const CustomPolarRadiusAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <text x={x} y={y} dy={16} textAnchor="middle" fill="#666" fontWeight="bold">
      {payload.value}
    </text>
  );
};

const ScoringRadarChart = () => {
  const [chartSize] = useState({
    width: 600, // Fixed width
    height: 500, // Fixed height
    cx: 300, // Centered horizontally
    cy: 250, // Centered vertically based on height
    radius: 200, // Adjust radius
  });

  return (
    <div
      style={{
        overflow: "hidden",
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <RadarChart
        cx={chartSize.cx}
        cy={chartSize.cy}
        outerRadius={chartSize.radius}
        width={chartSize.width}
        height={chartSize.height}
        data={data}
      >
        <PolarGrid gridType="circle" />
        <PolarAngleAxis
          dataKey="subject"
          tick={CustomPolarAngleAxisTick} // Use custom tick component
        />
        <PolarRadiusAxis
          angle={30}
          domain={[0, 100]}
          tick={CustomPolarRadiusAxisTick} // Use custom radius axis tick component
        />
        <Radar
          name="Mike"
          dataKey="A"
          stroke="#8884d8"
          fill="#8884d8"
          fillOpacity={0.1}
        />
        <Radar
          name="Lily"
          dataKey="B"
          stroke="#82ca9d"
          fill="#82ca9d"
          fillOpacity={0.1}
        />
      </RadarChart>
    </div>
  );
};

export default ScoringRadarChart;
