import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import talairo_ai from "../../images/svgs/talairo_ai.svg";
import talairo_title from "../../images/svgs/talairo_title.svg";
import { Divider } from "react-materialize";
import { useLocation, useHistory } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const SidebarList = [
  {
    name: "Home",
    path: "/home/dashboard-ai",
  },
  {
    name: "Notifications/Alerts",
    path: "/notification",
  },
  {
    name: "Support/Training",
    path: "/support",
  },
  {
    name: "Captain’s Log",
    path: "/captain",
  },
  {
    name: "Candidate Hub",
    path: "/home/candidate-hub",
  },
  // {
  //   name: "Interaction Hub",
  //   path: "/home/interaction-hub",
  // },
  {
    name: "Quick Match",
    path: "/home/quick-match",
  },
  {
    name: "Active Jobs",
    path: "/home/active-jobs",
  },
];

const HeaderList = [
  {
    name: "Help",
  },
  {
    name: "Notification",
  },
  {
    name: "Profile",
  },
  {
    name: "Sign Out",
  },
];

function AiSidebar({ isMobile }) {
  const location = useLocation();
  const history = useHistory();

  const handleListNavigate = (path) => {
    if (path === "/home/quick-match") {
      history.push({
        pathname: "/home/quick-match",
        state: { isNewQucikMatch: location.pathname !== "/home/quick-match" },
      });
    } else if (path === "/home/candidate-hub") {
      history.push({
        pathname: "/home/candidate-hub",
        state: {
          isNewCandidateHub: location.pathname !== "/home/candidate-hub",
        },
      });
    } else {
      history.push(path);
    }
  };

  const handleClickAddNew = () => {
    history.push({
      pathname: "/home/postjob/0",
      state: { isNew: true },
    });
  };
  return (
    <Box
      sx={{
        // width: "100%",
        maxWidth: isMobile ? 200 : 250,

        bgcolor: "background.paper",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "4px",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <img className="tickImg" src={talairo_ai} />
        <img className="talairoImg" src={talairo_title} />
      </div>
      <ListItem>
        <Button
          variant="contained"
          sx={{
            color: "white",
            backgroundColor: "Black",
            "&:hover": {
              backgroundColor: "Black",
            },
          }}
          endIcon={<AddIcon />}
          onClick={handleClickAddNew}
        >
          {"New"}
        </Button>
      </ListItem>
      <nav aria-label="main mailbox folders">
        <List>
          {SidebarList.map((elt, index) => {
            return (
              <ListItem
                disablePadding
                key={`list ${index}`}
                sx={{ marginLeft: "0px", paddingLeft: "10px" }}
              >
                <ListItemButton
                  selected={location.pathname.includes(elt.path)}
                  onClick={() => handleListNavigate(elt.path)}
                >
                  <ListItemText primary={elt?.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
          {/* <Divider></Divider> */}
          {isMobile ? (
            <>
              <ListItem divider />
              {HeaderList.map((elt, index) => {
                return (
                  <ListItem disablePadding key={`list ${index}`}>
                    <ListItemButton>
                      <ListItemText primary={elt?.name} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </>
          ) : null}
        </List>
      </nav>
    </Box>
  );
}

export default AiSidebar;
