import {
  Box,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState, useRef } from "react";
import {
  StyledButton,
  StyledFlexColumnView,
} from "../../CandidateHub/Components/StyledComponents";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTheme } from "@mui/material/styles";
import {
  BoldFormControlLabel,
  GenerateScoreButton,
  MatchTestTypography,
  OptionInfoContainer,
  QucikMatchCheckBoxContainer,
  QuickMatchContainer,
  StyledTextField,
  StyledUploadEnrichButton,
} from "./StyledComponents";
import { StyledCheckBox } from "../../../commonComponents/StyledComponents";
import ClearIcon from "@mui/icons-material/Clear";
import UploadIcon from "@mui/icons-material/Upload";

function StepOneMatchTest({
  jobDescriptionFileName,
  handleUploadJobDescription,
  jobDescriptionInputRef,
  handleJobDescriptionFileChange,
  resumeFileName,
  handleUploadResume,
  resumeInputRef,
  handleResumeFileChange,
  handleGenerateScore,
  loading,
  handleClearJobDescriptionFileName,
  handleClearResumeFileName,
  fileInputRefs,
  handleUploadFiles,
  uploadedFiles,
}) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");
  const fileInputRef = useRef(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Trigger the file input click
    }
  };

  const handleFileChange = (event) => {
    if (event.target.files.length > 0) {
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    setOpen(false); // Close the menu when an item is selected
  };

  const handleAdornmentClick = () => {
    setOpen(!open); // Toggle the menu open/close state
  };
  console.log(uploadedFiles?.length, "uploadedFiles");
  return (
    <QuickMatchContainer>
      <Grid container spacing={3} display={"flex"}>
        <Grid item xs={12}>
          <MatchTestTypography align={"center"}>
            {"1-Step Match Test"}
          </MatchTestTypography>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            spacing={4}
            justifyContent="start"
            alignItems="center"
            sx={{ mb: 3 }}
          >
            <Grid item xs={12} md={6} lg={5.5}>
              <StyledTextField
                variant="outlined"
                fullWidth
                size={"large"}
                placeholder="Upload Job description"
                label="Upload Job description"
                value={jobDescriptionFileName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {jobDescriptionFileName && (
                        <IconButton onClick={handleClearJobDescriptionFileName}>
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <StyledUploadEnrichButton
                variant="contained"
                component="span"
                onClick={handleUploadJobDescription}
                size={"large"}
              >
                {"Upload and Enrich"}
                <input
                  ref={jobDescriptionInputRef}
                  type="file"
                  hidden
                  onChange={handleJobDescriptionFileChange}
                  name="jobDescriptionFile"
                  id="jobDescriptionFile"
                />
              </StyledUploadEnrichButton>
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid item xs={12}>
          <Grid
            container
            spacing={4}
            justifyContent="start"
            alignItems="center"
            sx={{ mb: 3 }}
          >
            <Grid item xs={12} md={6} lg={5.5}>
              <StyledTextField
                variant="outlined"
                fullWidth
                size={"large"}
                label="Upload a resume "
                placeholder="Upload a resume"
                value={resumeFileName}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {resumeFileName && (
                        <IconButton onClick={handleClearResumeFileName}>
                          <ClearIcon />
                        </IconButton>
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <StyledUploadEnrichButton
                variant="contained"
                component="span"
                onClick={handleUploadResume}
                size={"large"}
              >
                {"Upload Candidate Resume"}
                <input
                  ref={resumeInputRef}
                  type="file"
                  hidden
                  onChange={handleResumeFileChange}
                  name="resumeFile"
                  id="resumeFile"
                />
              </StyledUploadEnrichButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <StyledButton
            variant="outlined"
            // onClick={handleAddResume}
          >
            Add Another Resume
          </StyledButton>
        </Grid> */}

        {uploadedFiles.map((resume, index) => (
          <Grid item xs={12}>
            <Grid container spacing={4} key={index}>
              <Grid item xs={12} md={6} lg={5.5}>
                <StyledTextField
                  variant="outlined"
                  fullWidth
                  size="large"
                  placeholder={`Upload Resume ${index + 1}`}
                  label={`Upload Resume ${index + 1}`}
                  value={resume.fileName}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {resume.fileName && (
                          <IconButton
                            onClick={() => handleClearResumeFileName(index)}
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}

        {uploadedFiles.length < 3 && (
          <Grid item xs={3}>
            <StyledUploadEnrichButton
              variant="contained"
              component="span"
              onClick={() => fileInputRefs.current.click()}
              size={"large"}
              startIcon={<UploadIcon />}
            >
              Upload Resumes
              <input
                ref={fileInputRefs}
                type="file"
                multiple
                hidden
                onChange={handleUploadFiles}
              />
            </StyledUploadEnrichButton>
          </Grid>
        )}

        {/* <Grid item xs={12}>
          <OptionInfoContainer>
            <Divider sx={{ width: isMobile ? "100%" : "40%", display: "flex" }}>
              Optional Info
            </Divider>
          </OptionInfoContainer>
        </Grid> */}

        {/* <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            gap={"20px"}
          >
            <StyledFlexColumnView>
              <Typography sx={{ color: "#828282" }}>
                {"Domain/Industry"}
              </Typography>
              <FormControl fullWidth>
                <TextField
                  select
                  label=""
                  value={value}
                  size="small"
                  onChange={handleChange}
                  sx={{ width: isMobile ? "100%" : "208px" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={handleAdornmentClick}
                        sx={{ cursor: "pointer" }}
                      >
                        <ExpandMoreIcon />
                      </InputAdornment>
                    ),
                  }}
                  SelectProps={{
                    native: false,
                    IconComponent: () => <></>,
                    open: open,
                    onOpen: () => setOpen(true),
                    onClose: () => setOpen(false),
                  }}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </TextField>
              </FormControl>
            </StyledFlexColumnView>
            <StyledFlexColumnView>
              <Typography sx={{ color: "#828282" }}>{"Country"}</Typography>
              <FormControl fullWidth>
                <TextField
                  select
                  label=""
                  value={value}
                  size="small"
                  onChange={handleChange}
                  sx={{ width: isMobile ? "100%" : "208px" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        onClick={handleAdornmentClick}
                        sx={{ cursor: "pointer" }}
                      >
                        <ExpandMoreIcon />
                      </InputAdornment>
                    ),
                  }}
                  SelectProps={{
                    native: false,
                    IconComponent: () => <></>,
                    // open: open,
                    // onOpen: () => setOpen(true),
                    // onClose: () => setOpen(false),
                  }}
                >
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </TextField>
              </FormControl>
            </StyledFlexColumnView>
          </Box>
        </Grid> */}

        {/* <Grid item xs={12}>
          <QucikMatchCheckBoxContainer>
            <BoldFormControlLabel
              control={<StyledCheckBox defaultChecked />}
              label="Check Social Activity"
            />
            <BoldFormControlLabel
              control={<StyledCheckBox defaultChecked />}
              label="DEI Focus"
            />
            <BoldFormControlLabel
              control={<StyledCheckBox defaultChecked />}
              label="Resume Gap Ok"
            />
            <BoldFormControlLabel
              control={<StyledCheckBox defaultChecked />}
              label="Govt/Security Cl."
            />
          </QucikMatchCheckBoxContainer>
        </Grid> */}

        <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
          <GenerateScoreButton
            onClick={handleGenerateScore}
            variant="contained"
            startIcon={
              loading ? <CircularProgress color="inherit" size={15} /> : null
            }
            disabled={loading}
            size={"large"}
          >
            {"Generate Scores"}
          </GenerateScoreButton>
        </Grid>
      </Grid>
    </QuickMatchContainer>
  );
}

export default StepOneMatchTest;
