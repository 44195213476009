import React from "react";
import { Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { GreyBoldTextTypo } from "./StyledComponents";

const data = {
  strengths: [
    "Lorem Ipsum",
    "Lorem Ipsum",
    "Lorem Ipsum",
    "Lorem Ipsum",
    "Lorem Ipsum",
  ],
  weaknesses: [
    "Lorem Ipsum",
    "Lorem Ipsum",
    "Lorem Ipsum",
    "Lorem Ipsum",
    "Lorem Ipsum",
  ],
};

const StrengthsAndWeaknesses = () => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <List>
          <ListItem sx={{ padding: "2px" }}>
            <GreyBoldTextTypo>{"Areas of Strength:"}</GreyBoldTextTypo>
          </ListItem>
          {data.strengths.map((item, index) => (
            <ListItem key={index} sx={{ padding: "2px" }}>
              <Add sx={{ marginRight: 1 }} />
              <ListItemText
                primary={
                  <Typography sx={{ color: "#828282", fontSize: "20px" }}>
                    {item}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12} md={6}>
        <List>
          <ListItem sx={{ padding: "2px" }}>
            <GreyBoldTextTypo>{"Areas of Weakness:"}</GreyBoldTextTypo>
          </ListItem>

          {data.weaknesses.map((item, index) => (
            <ListItem key={index} sx={{ padding: "2px" }}>
              <Remove sx={{ marginRight: 1 }} />
              <ListItemText
                primary={
                  <Typography sx={{ color: "#828282", fontSize: "20px" }}>
                    {item}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default StrengthsAndWeaknesses;
