import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import StepOneMatchTest from "./Components/StepOneMatchTest";
import axios from "axios";
import { uploadJdResume } from "../../api/api";
import CustomSnack from "../../commonComponents/Snack/CustomSnack";
import { useDispatch, useSelector } from "react-redux";
import {
  clearQuickMatchState,
  setQuickMatchState,
} from "../../redux/actions/quickMatch";

function QuickMatch() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isNewQucikMatch = location?.state?.isNewQucikMatch;
  const [jobDescriptionFileName, setJobDescriptionFileName] = useState("");
  const [resumeFileName, setResumeFileName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([
    { fileName: "", file: null },
  ]);
  const [loading, setLoading] = useState(false);
  const [snack, setSnack] = useState({
    open: false,
    color: "",
    message: "",
  });

  const jobDescriptionInputRef = useRef(null);
  const resumeInputRef = useRef(null);
  const fileInputRefs = useRef([]);

  const { jobDescriptionFile, resumeFile } = useSelector(
    (state) => state.quickMatch
  );

  useEffect(() => {
    if (isNewQucikMatch) {
      setResumeFileName("");
      setJobDescriptionFileName("");
      if (jobDescriptionInputRef.current) {
        jobDescriptionInputRef.current.value = ""; // Clear the file input
      }
      if (resumeInputRef.current) {
        resumeInputRef.current.value = ""; // Clear the file input
      }
      dispatch(clearQuickMatchState());
    } else {
      setResumeFileName(resumeFile?.name);
      setJobDescriptionFileName(jobDescriptionFile?.name);
    }
  }, [isNewQucikMatch]);
  console.log(isNewQucikMatch, "isNewQucikMatch");

  const handleUploadJobDescription = () => {
    if (jobDescriptionInputRef.current) {
      jobDescriptionInputRef.current.click();
    }
  };

  const handleJobDescriptionFileChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setJobDescriptionFileName(file.name); // Update file name in text field
      dispatch(
        setQuickMatchState({
          jobDescriptionFile: file,
        })
      );
    }
  };

  const handleGenerateScore = () => {
    setLoading(true);

    const formData = new FormData();
    if (jobDescriptionFile) {
      formData.append("jd_file", jobDescriptionFile);
    }
    // if (resumeFile) {
    //   formData.append("resume_file", resumeFile);
    // }
    uploadedFiles.forEach((resume, index) => {
      if (resume.file) {
        formData.append(`resume_file`, resume.file);
      }
    });
    formData.append("domain", "");
    formData.append("country", "");
    formData.append("id", "");

    axios
      .post(uploadJdResume(), formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })

      .then((response) => {
        if (response?.data?.success === true) {
          // setSnack({
          //   open: true,
          //   color: "success",
          //   message: response?.data?.message,
          // });
          setLoading(false);
          // history.push(
          //   `/home/quick-match/interaction-hub?id=${response?.data?.data?.id}`
          // );

          history.push(
            `/home/active-jobs/${response?.data?.data?.jd_info}/details`
          );
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err, "err");
        setSnack({
          open: true,
          color: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  const handleSnackClose = () => {
    setSnack({
      open: false,
      color: "",
      message: "",
    });
  };

  const handleClearJobDescriptionFileName = () => {
    setJobDescriptionFileName(""); // Clear the file name
    if (jobDescriptionInputRef.current) {
      jobDescriptionInputRef.current.value = ""; // Clear the file input
    }
    dispatch(
      setQuickMatchState({
        jobDescriptionFile: null,
      })
    );
  };

  const handleClearResumeFileName = (index) => {
    setUploadedFiles((prev) => {
      const updatedFiles = prev.filter((_, idx) => idx !== index);
      if (fileInputRefs.current) {
        fileInputRefs.current.value = "";
      }
      dispatch(
        setQuickMatchState({
          resumeFile: updatedFiles.map((resume) => resume.file),
        })
      );

      return updatedFiles;
    });
  };

  const handleUploadFiles = (event) => {
    const files = Array.from(event.target.files);

    setUploadedFiles((prev) => {
      const updatedFiles = [...prev];

      files.forEach((file) => {
        if (updatedFiles.filter((uploaded) => uploaded.file).length < 3) {
          const emptyIndex = updatedFiles.findIndex(
            (uploaded) => uploaded.fileName === ""
          );
          if (emptyIndex !== -1) {
            updatedFiles[emptyIndex] = { fileName: file.name, file }; // Update with new file
          } else if (updatedFiles.length < 3) {
            updatedFiles.push({ fileName: file.name, file }); // Add to the end if under the limit
          }
        }
      });

      dispatch(
        setQuickMatchState({
          resumeFile: updatedFiles.map((resume) => resume.file),
        })
      );

      return updatedFiles;
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <StepOneMatchTest
          jobDescriptionFileName={jobDescriptionFileName}
          resumeFileName={resumeFileName}
          handleUploadJobDescription={handleUploadJobDescription}
          handleJobDescriptionFileChange={handleJobDescriptionFileChange}
          jobDescriptionInputRef={jobDescriptionInputRef}
          resumeInputRef={resumeInputRef}
          handleGenerateScore={handleGenerateScore}
          loading={loading}
          handleClearJobDescriptionFileName={handleClearJobDescriptionFileName}
          handleClearResumeFileName={handleClearResumeFileName}
          fileInputRefs={fileInputRefs}
          handleUploadFiles={handleUploadFiles}
          uploadedFiles={uploadedFiles}
        />
      </Grid>
      <CustomSnack
        open={snack.open}
        color={snack.color}
        message={snack.message}
        onClose={handleSnackClose}
      />
    </Grid>
  );
}

export default QuickMatch;
